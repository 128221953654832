import { Link, useLocation } from "react-router-dom"

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/sidebar/sidebar"
import { SidebarNavItem } from "@/types/nav"

export function NavSubMenu({ items }: { items: SidebarNavItem[] }) {
  const { pathname } = useLocation()
  return (
    <>
      {items.map((item) =>
        item.items
          .reduce(
            (acc, item) => {
              const section = item.section || item.title
              const group = acc.find((g) => g.section === section)
              if (group) {
                group.items.push(item)
              } else {
                acc.push({ section, items: [item] })
              }
              return acc
            },
            [] as {
              section: string
              items: SidebarNavItem[]
            }[]
          )
          .map((group) => (
            <SidebarGroup key={group.section}>
              <SidebarGroupLabel className="capitalize">
                {group.section}
              </SidebarGroupLabel>
              <SidebarMenu>
                {group.items.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      asChild
                      isActive={item.path.includes(pathname)}
                    >
                      <Link to={item.href}>{item.title}</Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroup>
          ))
      )}
    </>
  )
}
