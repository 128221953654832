import * as React from "react"
import { useLocation } from "react-router-dom"

import { NavMain } from "@/components/sidebar/nav-main"
import { NavUser } from "@/components/sidebar/nav-user"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/sidebar/sidebar"
import { TeamSwitcher } from "@/components/sidebar/team-switcher"
import { navigation } from "@/data/menu"
import { teams } from "@/data/teams"
import { useUser } from "@/lib/auth"

import { NavSettings } from "./nav-settings"
import { NavSubMenu } from "./nav-submenu"

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { pathname } = useLocation()

  const navMain = navigation.filter((item) => item.section === "main")
  const navSettings = navigation.filter((item) => item.section === "settings")
  const navSubmenu = navigation.filter((nav) => nav.path.includes(pathname))

  const { data: user } = useUser()
  const dataUser = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    avatar: user?.photo ?? "",
    roles: user?.roles ?? [],
  }

  return (
    <Sidebar
      collapsible="icon"
      className="overflow-hidden [&>[data-sidebar=sidebar]]:flex-row"
      {...props}
    >
      <Sidebar
        collapsible="none"
        className="!w-[calc(var(--sidebar-width-icon)_+_1px)] border-r"
      >
        <SidebarHeader>
          <TeamSwitcher teams={teams} />
        </SidebarHeader>
        <SidebarContent>
          <NavMain items={navMain} />
          <NavSettings items={navSettings} />
        </SidebarContent>
        <SidebarFooter>
          <NavUser user={dataUser} />
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <Sidebar collapsible="none" className="hidden flex-1 md:flex">
        <SidebarContent className="mt-2">
          <NavSubMenu items={navSubmenu} />
        </SidebarContent>
      </Sidebar>
    </Sidebar>
  )
}
