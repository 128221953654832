import { useTranslation } from "react-i18next"
import { Link, useRouteError } from "react-router-dom"

import { ContentLayout } from "@/components/layouts"

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  const { t } = useTranslation()

  return (
    <ContentLayout title={t("ErrorTitle")}>
      <div id="error-page">
        <h1>{t("ErrorTitle")}</h1>
        <p>{t("ErrorDescription")}</p>
        <Link to="/" replace>
          {t("GoToHome")}
        </Link>
      </div>
    </ContentLayout>
  )
}
