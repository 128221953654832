export type Teams = {
  name: string
  logo: string
  plan: string
}

export const teams: Teams[] = [
  {
    name: "Army Security",
    logo: "/images/logo.png",
    plan: "PT A.R.M.Y",
  },
]
