import { type DialogProps } from "@radix-ui/react-dialog"
import {
  CircleIcon,
  LaptopIcon,
  MoonIcon,
  SunIcon,
} from "@radix-ui/react-icons"
import { Search } from "lucide-react"
import * as React from "react"
import { useNavigate } from "react-router-dom"

import { navigation } from "@/data/menu"
// import { useAuthorizationModule } from "@/lib/authorization"
import { cn } from "@/utils/classes"

import { useTheme } from "../theme"
import { Button } from "../ui/button"
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../ui/control"

export function CommandMenu({ ...props }: DialogProps) {
  const navigate = useNavigate()
  const { setTheme } = useTheme()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if ((e.key === "k" && (e.metaKey || e.ctrlKey)) || e.key === "/") {
        if (
          (e.target instanceof HTMLElement && e.target.isContentEditable) ||
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLTextAreaElement ||
          e.target instanceof HTMLSelectElement
        ) {
          return
        }

        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  const runCommand = React.useCallback((command: () => unknown) => {
    setOpen(false)
    command()
  }, [])

  // const { checkAccess } = useAuthorizationModule()

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          "relative w-full justify-start rounded-md bg-background pl-8 text-sm font-normal text-muted-foreground sm:pr-12 md:w-[200px] lg:w-[336px]"
        )}
        onClick={() => setOpen(true)}
        {...props}
      >
        <Search className="absolute left-2.5 top-[2.5] size-4 text-muted-foreground" />
        <span className="hidden lg:inline-flex">Search menu...</span>
        <span className="inline-flex lg:hidden">Search...</span>
        <kbd className="pointer-events-none absolute right-2.5 top-2.5 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {navigation.map((group) => {
            // Check if the user has access to this item
            // const hasAccess = checkAccess({ allowedModule: group.code })

            // // Skip rendering if the user doesn't have access
            // if (!hasAccess) return null

            return (
              <CommandGroup key={group.title} heading={group.title}>
                {group.items.map((navItem) => (
                  <CommandItem
                    key={navItem.href}
                    value={navItem.title}
                    onSelect={() => {
                      runCommand(() => navigate(navItem.href as string))
                    }}
                  >
                    <div className="mr-2 flex size-4 items-center justify-center">
                      <CircleIcon className="size-3" />
                    </div>
                    {navItem.title}
                  </CommandItem>
                ))}
              </CommandGroup>
            )
          })}
          <CommandSeparator />
          <CommandGroup heading="Theme">
            <CommandItem onSelect={() => runCommand(() => setTheme("light"))}>
              <SunIcon className="mr-2 size-4" />
              Light
            </CommandItem>
            <CommandItem onSelect={() => runCommand(() => setTheme("dark"))}>
              <MoonIcon className="mr-2 size-4" />
              Dark
            </CommandItem>
            <CommandItem onSelect={() => runCommand(() => setTheme("system"))}>
              <LaptopIcon className="mr-2 size-4" />
              System
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  )
}
