export const convertNumberToThousand = (
  value: number | string,
  separator: string = "."
) => {
  if (typeof value === "number") {
    return value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  }

  return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const getInitialsName = (name: string) => {
  return name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase()
}

export const unmaskCharacters = (
  value: string,
  returnType: string | number = "string"
) => {
  // remove "Rp", dot, and space
  const numericValue = value.replace(/[^\d]/g, "")

  if (returnType === "number") {
    return parseInt(numericValue, 10)
  }
  return numericValue
}

export const unmaskCharactersString = (value: string): string => {
  // remove "Rp", dot, and space
  return value.replace(/[^\d]/g, "")
}

export const unmaskCharactersNumber = (value: string | number): number => {
  if (typeof value === "string") {
    const numericValue = value.replace(/[^\d]/g, "")
    return parseInt(numericValue, 10)
  }

  return value
}

export const getValidatedDate = (date: string): string => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  return date && dateRegex.test(date) ? date : ""
}

export const getValidatedId = (id: string): number | null => {
  const idRegex = /^\d+$/
  return id && idRegex.test(id) ? parseInt(id) : null
}

export const getFileNameFromUrl = (url: string): string => {
  return url.split("/").pop() || ""
}
