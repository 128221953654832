import { api } from "@/lib/api-client"

import { RefreshTokenResponse } from "../types/api"
import { RefreshTokenRequest } from "../types/form"

export const refreshToken = (
  data: RefreshTokenRequest
): Promise<RefreshTokenResponse> => {
  return api.post("/auth/refresh-token", data)
}
