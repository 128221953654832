import React from "react"
import { Link, useLocation } from "react-router-dom"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/collections"
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbPage,
  BreadcrumbEllipsis,
} from "@/components/ui/navigation"

export const DynamicBreadcrumb = () => {
  const { pathname } = useLocation()

  // Separate the pathname into an array of strings and filter out any empty strings
  const pathnames = pathname.split("/").filter((x) => x)

  const getDisplayName = (value: string) => {
    if (value === "app") return "dashboard"

    return value.replace(/-/g, " ")
  }

  // Dashboard breadcrumb
  if (pathnames.length === 1 && pathnames[0] === "app") {
    return (
      <Breadcrumb className="hidden md:flex">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/app" className="capitalize">
                Dashboard
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Overview</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    )
  }

  if (pathnames.length <= 4) {
    return (
      <Breadcrumb className="hidden md:flex">
        <BreadcrumbList>
          {pathnames.map((value, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
            const isLast = index === pathnames.length - 1

            return isLast ? (
              <BreadcrumbItem key={index}>
                <BreadcrumbPage className="capitalize">
                  {getDisplayName(value)}
                </BreadcrumbPage>
              </BreadcrumbItem>
            ) : (
              <React.Fragment key={index}>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to={routeTo} className="capitalize">
                      {getDisplayName(value)}
                    </Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </React.Fragment>
            )
          })}
        </BreadcrumbList>
      </Breadcrumb>
    )
  }

  // If pathnames length is greater than 4, use dropdown for middle items
  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        {/* First item */}
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={`/${pathnames[0]}`} className="capitalize">
              {getDisplayName(pathnames[0])}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />

        {/* Dropdown for middle items */}
        <BreadcrumbItem>
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center gap-1">
              <BreadcrumbEllipsis className="size-4" />
              <span className="sr-only">Toggle menu</span>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              {pathnames.slice(1, -1).map((value, index) => {
                const routeTo = `/${pathnames.slice(0, index + 2).join("/")}`
                return (
                  <Link key={index} to={routeTo} className="capitalize">
                    <DropdownMenuItem>{getDisplayName(value)}</DropdownMenuItem>
                  </Link>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </BreadcrumbItem>
        <BreadcrumbSeparator />

        {/* Last item */}
        <BreadcrumbItem>
          <BreadcrumbPage className="capitalize">
            {getDisplayName(pathnames[pathnames.length - 1])}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
